import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Process1 from '../../../images/work/21_MONTEREY_PINE/PROCESS/PIN_01.jpg'
import Process2 from '../../../images/work/21_MONTEREY_PINE/PROCESS/PIN_02.jpg'
import Process1s from '../../../images/work/21_MONTEREY_PINE/PROCESS/450X280/PIN-SM_01.jpg'
import Process2s from '../../../images/work/21_MONTEREY_PINE/PROCESS/450X280/PIN-SM_02.jpg'

const Residence21 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="PINE RESIDENCE"
        residenceLocation="MONTEREY, CALIFORNIA"
        infoList={['Remodel of existing historic 1,200 SF single family residence. Addition of 500 SF to a total of 1,700 SF of floor area.', 'Design - 2018-2019', 'Construction - 2019', 'General Contractor: Coast Line Construction, Inc.', 'Structural Engineer: Structures Engineering, Inc.']}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[Process1, Process2]}
        processPhotosSmall={[Process1s, Process2s]}
      />
    </Container>
  </Layout>
)

export default Residence21;
